











































































































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import { VeeValidateObserverRef } from '@/models/Common/Validation'
import Setting from '@/store/modules/Setting'
import Auth from '@/store/modules/Auth'
import SettingService from '@/services/SettingService'
import AuthService from '@/services/AuthService'
import store from '@/store'
import UserService from '@/services/UserService'
import UserInfo from '@/store/modules/UserInfo'
import moment from 'moment'
import {
  getUnreadCount,
  getLikeCount,
  getFavouriteCount
} from '@/helpers/GetNotificationCount'

const SettingModule = getModule(Setting, store)
const AuthModule = getModule(Auth, store)
const UserModule = getModule(UserInfo, store)

@Component
export default class Login extends Vue {
  $refs!: {
    observer: VeeValidateObserverRef
  }
  private line_url: any = ''
  private random_state: any = ''
  private redirect_url: any = window.location.origin + '/line-login/'
  private resetPasswordExpired: boolean = false
  private current_date: any = ''
  private failedLoginMsg: boolean = false
  private isLoginWithEmail: boolean = true
  private employee_email: string = ''
  private company_id: string = ''
  private employee_number: string = ''
  private employee_password: string = ''
  private isLoadingLogin: boolean = false
  private showErrorLoginMsg: boolean = false
  private errorIdentifyAccount: boolean = false
  private notifications: any[] = []
  private login_status_msg: any = ''

  created() {
    this.random_state = Math.random()
      .toString(36)
      .substr(5, 9)
    this.line_url = `https://access.line.me/oauth2/v2.1/authorize?client_id=1656420858&scope=openid+profile+email&state=${this.random_state}&response_type=code&redirect_uri=${this.redirect_url}`
    this.current_date = moment.now()
    sessionStorage.clear()
    localStorage.clear()
    this.getNotificationBeforeLogin()
    if (this.$route.query.reset_password_expired === 'true') {
      this.resetPasswordExpired = true
    } else return
  }

  loginWithEmail() {
    this.isLoginWithEmail = true
    this.employee_email = ''
    this.employee_password = ''
    this.showErrorLoginMsg = false
    this.failedLoginMsg = false
    this.$refs.observer.reset()
  }

  login() {
    this.$refs.observer.validate().then(async isValidate => {
      if (isValidate) {
        this.isLoadingLogin = true
        let loginWithEmail = {
          email: this.employee_email,
          password: this.employee_password
        }
        await AuthService.login(loginWithEmail)
          .then(response => {
            if (response.status === 200) {
              let token = response.data.items.token
              AuthModule.SET_NEW_TOKEN(token)
              if (token) {
                this.checkUserBelongToGroup()
              }
              AuthModule.SET_NEW_STATUS_LOGIN_FROM_LOGIN_BY_EMAIL(true)
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.login_status_msg = error.response.data.message[0]
              this.showErrorLoginMsg = true
              this.failedLoginMsg = false
            } else {
              this.failedLoginMsg = true
              this.showErrorLoginMsg = false
            }
          })
        this.isLoadingLogin = false
      }
    })
  }

  checkUserBelongToGroup() {
    AuthService.checkUserBelongToGroup()
      .then(response => {
        if (response.status === 200) {
          if (response.data.is_user_belong_to_group === true) {
            this.$router.push({
              path: this.$route.query.redirect
                ? String(this.$route.query.redirect)
                : '/'
            })
            this.resetStoreToDefault()
            this.getUserInfo()
            this.getUserSetting()
            this.getUserProfile()

            //call nav_header count
            getUnreadCount()
            getLikeCount()
            getFavouriteCount()
          } else {
            this.$router.push({ name: 'employee-without-group' })
          }
        }
      })
      .catch(error => {
        if (error.response.data.is_request_join_group) {
          this.$bvModal.show('modal-info-login')
        } else {
          this.$router.push({ name: 'employee-without-group' })
          console.log(error)
        }
      })
  }

  resetStoreToDefault() {
    UserModule.RESET_DEFAULT()
    SettingModule.RESET_DEFAULT()
  }

  getUserProfile() {
    UserService.getUserProfile()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_PROFILE(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getUserInfo() {
    UserService.getUserInfo()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_INFO(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getNotificationBeforeLogin() {
    UserService.getNotificationBeforeLogin()
      .then(response => {
        if (response.status === 200) {
          this.notifications = response.data.map((item: any) => {
            return {
              content: item.content,
              public_date: moment(item.public_date).format('MM/DD'),
              timestamp: moment(item.public_date).valueOf()
            }
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getUserSetting() {
    SettingService.getUserSetting()
      .then(response => {
        if (response.status === 200) {
          SettingModule.HANDLE_USER_SETTING(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  resetMsg() {
    this.failedLoginMsg = false
    this.showErrorLoginMsg = false
    this.resetPasswordExpired = false
  }
}
